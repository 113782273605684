<template>
	<div>
		<div v-if="page.length < 1">
			<div>
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-input v-model="keyword" placeholder="Enter page name"></ion-input>
					<ion-button color="light" v-if="keyword != null && keyword != ''" @click.prevent="clearSearch()">
						<ion-icon :icon="close" color="dark"></ion-icon>
					</ion-button>
				</ion-item>
			</div>
			<!-- Pages -->
			<div v-if="matchedPages.length > 0" class="padding-top-md">
				<ion-list>
					<ion-item v-for="page in matchedPages" :key="page" button @click.prevent="dataUp(page)">
						<div tabindex="0"></div>
						<ion-avatar v-if="page.image != null && page.image != ''" slot="start">
							<img :src="page.image" >
						</ion-avatar>
						<ion-label>
							<span>{{page.name}}</span>
							<p class="text-uppercase">{{page.location}}</p>
						</ion-label>
						
					</ion-item>
				</ion-list>
			</div>
		</div>
	</div>
</template>

<script>
import { IonList, IonItem, IonAvatar, IonInput, IonIcon, IonButton, IonLabel, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { close } from 'ionicons/icons';

export default defineComponent({
	name: 'SelectPage',
	props: [],
	components: {
		IonList, IonItem, IonAvatar, IonInput, IonIcon, IonButton, IonLabel
	},
	setup() {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const pages = ref([]);
		const page = ref([]);
		const keyword = ref(null);
		const matchedPages = ref([]);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }

		onMounted(() => {
			getPages()
		})

		function getPages() {
			presentLoading()
			apiClient.get('/api/get/pages', {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				pages.value = response.data
			}).catch(error => {
				console.log(error)  
			});      
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function searchPagesByKeyword() {
			if(keyword.value != null && keyword.value != '') {
				matchedPages.value = []
				var str = keyword.value
				for(var i=0; i<pages.value.length;i++) {
					var slug = pages.value[i].slug.toLowerCase()
					var name = pages.value[i].name.toLowerCase()
					str = str.toLowerCase()
			
					if(slug.includes(str) || name.includes(str)) {
						matchedPages.value.push({
							id: pages.value[i].id,
							name: pages.value[i].name,
							slug: pages.value[i].slug,
							image: pages.value[i].image,
							location: pages.value[i].city+', '+pages.value[i].state,               
						})
					}
					
				}
			}
		}

		function clearSearch() {
			keyword.value = null
			matchedPages.value = []
			page.value = []
		}

		return {
			authUser, pages, matchedPages, page, searchPagesByKeyword, close, keyword, openToast, toastMessage, clearSearch
		}
	},
	methods: {
		dataUp: function(item) {
			this.$emit('get-the-page', {
				id: item.id,
				name: item.name,
				slug: item.slug,
				image: item.image
			})
			this.toastMessage = 'You have added a page.'
			this.openToast()
			this.clearSearch()
		}
	},
	watch: {
		keyword: function() {
			if(this.keyword == '') {
				this.keyword = null
			}
			if(this.keyword === null) {
				this.matchedPages = []
			} else {
				if(this.keyword.length >= 2) {
					this.searchPagesByKeyword()
				}
			}			
		}
	}
});
</script>